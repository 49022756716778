import router from '@/router'
import ability from '@/libs/acl/ability'
import { initialAbility } from '@/libs/acl/config'
import csrf from '@core/auth/csrf'
import sanctumDefaultConfig from './sanctumDefaultConfig'

export default class SanctumService {
  // Will be used by this service for making API calls
  axiosIns = null

  // sanctumConfig <= Will be used by this service
  sanctumConfig = { ...sanctumDefaultConfig }

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.sanctumConfig = { ...this.sanctumConfig, ...jwtOverrideConfig }

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        const { response } = error
        if (response && response.status === 401) {
          this.resetState()
          router.push({ name: 'login' }).catch(() => {})
        }
        return Promise.reject(error)
      },
    )
  }

  // eslint-disable-next-line class-methods-use-this
  resetState() {
    // Remove userData from localStorage
    localStorage.removeItem('userData')

    // Reset ability
    ability.update(initialAbility)
  }

  login(...args) {
    return csrf.get(this.axiosIns).then(() => this.axiosIns.post(this.sanctumConfig.loginEndpoint, ...args))
  }

  logout(...args) {
    return csrf.get(this.axiosIns).then(() => this.axiosIns.post(this.sanctumConfig.logoutEndpoint, ...args))
  }

  me() {
    return this.axiosIns.get(this.sanctumConfig.meEndpoint)
  }

  forgotPassword(...args) {
    return csrf.get(this.axiosIns).then(() => this.axiosIns.post(this.sanctumConfig.forgotPassword, ...args))
  }

  resetPasswordValidate(...args) {
    return csrf.get(this.axiosIns).then(() => this.axiosIns.post(this.sanctumConfig.resetPasswordValidate, ...args))
  }

  resetPassword(...args) {
    return csrf.get(this.axiosIns).then(() => this.axiosIns.post(this.sanctumConfig.resetPassword, ...args))
  }
}
